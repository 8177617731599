@import '../../styles/_inocrowd-variables.scss';
@import '../../styles/animations.scss';
@import 'node_modules/bootstrap/scss/mixins';

%base-button {
  color: $white;
  font-size: 0.95rem;
  font-weight: 500;
  line-height: 14px;
  border-radius: 4px;
  white-space: nowrap;
  padding: 13px 20px;
  opacity: 1;
  cursor: pointer;
  transition: all 300ms ease-in-out;

  &.small {
    font-size: 0.8125rem;
    padding: 8px 20px;
  }

  &.big {
    font-size: 1.07rem;
    padding: 17px 20px;
  }

  &.small {
    font-size: 0.8125rem;
    padding: 9px 28px;
  }

  &:hover {
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.3;
    cursor: default;
  }

  &:focus {
    outline: none;
  }
}

.primary-button {
  @extend %base-button;

  border: 1px solid $pale-violetred;
  background-color: $pale-violetred;
  box-shadow: 0 3px 6px 0 rgba(255, 100, 198, 0.2);

  &:active {
    background-color: $primary-button-active;
    box-shadow: 0 7px 16px 0 rgba(255, 100, 198, 0.2);
  }

  &:disabled {
    background-color: $pale-violetred;
  }
}

.secondary-button {
  background-color: inherit;
  border: 2px solid $pale-violetred;
  border-radius: 4px;
  font-size: 0.95rem;
  color: $pale-violetred;
  white-space: nowrap;
  padding: 10px 18px 10px 18px;
  opacity: 1;
  cursor: pointer;

  &.small {
    font-size: 0.875rem;
    font-weight: 500;
    letter-spacing: 0.1px;
    line-height: 1.3125rem;
    padding: 4px 12px;
  }

  &:hover {
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.3;
    border: 1px solid $pale-violetred;
    cursor: default;
  }

  &:focus {
    outline: none;
  }
}

.tertiary-button {
  @extend %base-button;
  border: 1px solid $medium-blue;
  background-color: $medium-blue;
  box-shadow: 0 7px 16px 0 rgba(16, 29, 191, 0.2);

  &:active {
    background-color: $valhalla;
    box-shadow: inset 0 1px 3px 0 rgba($black, 0.2), 0 7px 16px 0 rgba(16, 29, 191, 0.2);
  }

  &:disabled {
    background-color: $medium-blue;
  }
}

.outline-button {
  color: $medium-blue;
  background-color: white;
  border: 1px solid $medium-blue;
  box-shadow: 0 7px 16px 0 rgba(16, 29, 191, 0.1);

  &.small {
    box-shadow: 0 3px 6px 0 rgba(235, 237, 248, 0.3);
  }

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.8;
    background-color: white;
  }

  &:disabled {
    opacity: 0.3;
    cursor: default;
    background-color: inherit;
  }

  &:focus {
    outline: none;
    color: $medium-blue;
    background-color: white;
    box-shadow: none;
    border: 1px solid $medium-blue;
  }
}

.oval-button {
  border: none;
  color: white;
  height: 75px;
  width: 75px;
  text-align: center;
  align-self: center;
  border-radius: 50%;
  background-color: $pale-violetred;
  box-shadow: 0 2px 10px 0 rgba($oval-button-shadow, 0.41);
  cursor: pointer;
  user-select: none;
  transition: 0.2s ease-in-out;

  &:hover {
    opacity: 0.95;
    box-shadow: 0 5px 10px 0 rgba($oval-button-shadow, 0.41);
  }

  &:active {
    background-color: $primary-button-active;
    box-shadow: 0 7px 10px 0 rgba($oval-button-shadow, 0.41);
  }

  &:disabled {
    cursor: default;
    opacity: 0.3;
    background-color: $pale-violetred;
  }

  &:focus {
    outline: none;
  }

  .material-icons {
    vertical-align: middle;
  }
}

.clear-button {
  border: none;
  background: white;
  color: $clear-filter-color;
  font-size: 0.8125rem;
  font-weight: 500;
  letter-spacing: 0.09px;
  cursor: pointer;
  user-select: none;
  text-decoration: underline;
  transition: 0.2s ease-in-out;

  &:hover {
    opacity: 0.8;
  }

  &:disabled {
    cursor: default;
    opacity: 0.3;
  }

  &:focus {
    outline: none;
  }
}

.underline-button {
  border: none;
  background-color: inherit;
  color: $gray;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  user-select: none;
  transition: 0.2s ease-in-out;


  span {
    font-size: 1rem;
    line-height: 1.125rem;
    display: inline-block;
    border-bottom: 1px solid $gray;
    margin-bottom: 0;
  }

  &:hover {
    opacity: 0.8;
  }

  &:disabled {
    cursor: default;
    opacity: 0.3;
  }

  &:focus {
    outline: none;
  }
}

.filter-button {
  color: $gray;
  font-size: 0.9375rem;
  letter-spacing: 0.1px;
  font-weight: 500;
  white-space: nowrap;
  padding: 9px 20px;
  opacity: 1;
  cursor: pointer;
  border: 1px solid rgba($gray, 0.4);
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 6px 10px 0 rgba($filters-elements-shadow, 0.1);
  transition: all 300ms ease-in-out;

  display: flex;
  flex-direction: row;

  &.isFiltered {
    border-color: $pale-violetred;
    color: $pale-violetred;

    svg {
      g {
        animation: filterColor 300ms ease-in-out forwards;
      }
    }
  }

  &.normal {
    svg {
      g {
        animation: removeFilterColor 300ms ease-in-out forwards;
      }
    }
  }

  &:hover {
    opacity: 0.8;
  }

  &:focus {
    outline: none;
  }

  &.has-icon {
    padding: 9px 10px 9px 20px;
  }

  .button-icon-wrapper {
    padding-left: 18px;

    svg {
      vertical-align: middle;
    }
  }
}

@keyframes removeFilterColor {
  0% {
    fill: $pale-violetred;
  }
  100% {
    fill: $gray;
  }
}

@keyframes filterColor {
  0% {
    fill: $gray;
  }
  100% {
    fill: $pale-violetred;
  }
}

.download-button {
  height: 42px;
  background-color: inherit;
  border: 1px solid #AFB6BE;
  border-radius: 4px;
  color: $gray;
  font-size: 1rem;
  letter-spacing: 0.11px;
  line-height: 42px;
  padding: 0 10px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.3;
    cursor: default;
  }

  .file-icon {
    margin-right: 13px;
  }
}

.round-button {
  width: fit-content;
  color: $gray;
  border: 1px solid rgba($gray, 0.5);
  border-radius: 21px;
  text-align: center;
  min-height: 42px;
  line-height: 2.625rem;
  vertical-align: middle;
  padding: 0 14px 0 14px;
  white-space: nowrap;

  i {
    line-height: 2.625rem;
    vertical-align: middle;
    padding-right: 5px;
  }

  &.primary {
    color: $pale-violetred;
    border: 1px solid $pale-violetred;
    padding: 0 23px;
  }

  @include media-breakpoint-down(lg) {
    font-size: 0.875rem;
    letter-spacing: 0.08px;
    line-height: 1.75rem;
    min-height: 1.9375rem;

    i {
      font-size: 1.25rem;
      line-height: inherit;
    }

    &.primary {
      padding: 0 15px;
    }
  }

  &.small {
    font-size: 0.875rem;
    letter-spacing: 0.08px;
    line-height: 1.8125rem;
    min-height: 1.9375rem;
    padding: 0 15px;

    i {
      font-size: 1.25rem;
      line-height: inherit;
    }

    @include media-breakpoint-down(lg) {
      font-size: 0.875rem;
      letter-spacing: 0.08px;
      line-height: 1.8125rem;
      min-height: 31px;
      padding: 0 15px;
    }
  }
}

.linkedin-button {
  @extend %base-button;
  border: 1px solid $linkedin_color;
  background-color: $linkedin_color;
  box-shadow: 0 2px 6px 0 $linkedin_shadow;
  position: relative;

  span {
    vertical-align: text-bottom;
  }

  .button-icon-wrapper {
    top: 0;
    left: 0;
    height: 100%;
    width: 42px;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.08);

    .button-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &:active {
    background-color: $linkedin_color;
    box-shadow: 0 7px 16px 0 $linkedin_shadow;
  }

  &:disabled {
    background-color: $linkedin_color;
  }
}

.back-homepage-btn {
  position: absolute;
  top: 45px;
  left: 45px;
  cursor: pointer;
  border: none;
  margin: 0;
  padding: 0;
  background: transparent;
  display: flex;
  align-items: center;
  height: 20px;
  opacity: 0.6;
  color: $gray;
  font-size: 14px;
  letter-spacing: 0.09px;
  line-height: 21px;
  transition: all 300ms ease-in-out;


  &:hover, &:active {
    opacity: 0.8;
  }

  &:focus {
    outline: none;
  }

  svg {
    margin-right: 8px;
  }

  @include media-breakpoint-down(xs) {
    top: 35px;
    left: 30px;
  }
}

.social-share-button{
  display: inline-flex;
  border-radius: 100%;
  padding: 0;
  margin: 0;
  border: 0;
  background-color: #333333;
  width: 40px;
  height: 40px;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 400ms;

  &.facebook:hover{
    background-color: #3b5998;
  }
  &.linkedin:hover{
    background-color: #0077b5;
  }
  &.twitter:hover{
    background-color: #1da1f2;
  }
  &.email:hover{
    background-color: #0017BB;
  }
  &.copy:hover{
    background-color: #DB4373;
  }

  &:not(:first-child){
    margin-left: 11px;
  }

  &:focus,
  &:active{
    outline: 0;
  }

  svg{
    width: 100%;
    height: 100%;
  }

  &.email svg,
  &.copy svg{
    padding: 8px;
  }
}

.copy-url-popover{
  background: #fff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 6px 3px rgba(0,0,0,0.05);
  margin-top: 10px;
  animation-name: fadeOut;
  animation-duration: 1.9s;
  animation-direction: normal;
  animation-delay: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  .popover-body{
    font-size: 12px;
    font-weight: 500;
    color: #333;
    letter-spacing: 0.1px;
  }
}
