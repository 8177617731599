@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';

$font-family-base: Poppins, Verdana, sans-serif;
$breadcrumb-divider: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSI3cHgiIGhlaWdodD0iMTNweCIgdmlld0JveD0iMCAwIDcgMTMiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+ICAgICAgICA8dGl0bGU+UGF0aCAxNjwvdGl0bGU+ICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPiAgICA8ZGVmcz48L2RlZnM+ICAgIDxnIGlkPSJJbm9jcm93ZC1QbGF0Zm9ybSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+ICAgICAgICA8ZyBpZD0iRGVzYWZpby0tLXN1Ym1pdC1zb2x1dGlvbi0tLWFzc2lzdGFudCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTY3NS4wMDAwMDAsIC0xNTUuMDAwMDAwKSIgZmlsbD0iI0ExQUFCMyIgZmlsbC1ydWxlPSJub256ZXJvIj4gICAgICAgICAgICA8ZyBpZD0iR3JvdXAtMjQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE1NS4wMDAwMDAsIDE1MS4wMDAwMDApIj4gICAgICAgICAgICAgICAgPHBhdGggZD0iTTUyMC40NDM4NzEsNS41MDMyMTAwMSBDNTIwLjE2NTk1Niw1LjE5NjA2ODMzIDUyMC4xODk2NDgsNC43MjE3ODYwMSA1MjAuNDk2NzksNC40NDM4NzA4IEM1MjAuODAzOTMyLDQuMTY1OTU1NTkgNTIxLjI3ODIxNCw0LjE4OTY0ODMyIDUyMS41NTYxMjksNC40OTY3ODk5OSBMNTI3LjAxMzU5OCwxMC41MjgxODQzIEw1MjEuNTUzNzcyLDE2LjUwNTgwMzEgQzUyMS4yNzQ0MjQsMTYuODExNjQyOCA1MjAuODAwMDM3LDE2LjgzMzExOTIgNTIwLjQ5NDE5NywxNi41NTM3NzE4IEM1MjAuMTg4MzU3LDE2LjI3NDQyNDUgNTIwLjE2Njg4MSwxNS44MDAwMzY2IDUyMC40NDYyMjgsMTUuNDk0MTk2OSBMNTI0Ljk4NjQwMiwxMC41MjM0NDgxIEw1MjAuNDQzODcxLDUuNTAzMjEwMDEgWiIgaWQ9IlBhdGgtMTYiPjwvcGF0aD4gICAgICAgICAgICA8L2c+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4=);

$header-height: 70px;
$header-mobile-height: 56px;

$footer-height: 367px;
$footer-md-height: 669px;

$footer-save-height: 80px;
$footer-save-height-mobile-action: 60px;
$footer-save-height-mobile: 105px;
$footer-save-height-mobile-xs: 130px;
$footer-save-height-mobile-action-xs: 100px;

$alert-height: 45px;
$alert-success-text-color: #42ca9e;
$alert-success-background-color: rgba(66, 202, 158, 0.11);
$alert-error-text-color: #db4373;
$alert-error-background-color: rgba(#db4373, 0.11);
$alert-info-text-color: #5a6f85;
$alert-info-background-color: #F6F6FA;

$blue: #0056C0;
$medium-blue: #0017bb;
$aqua: #0adaf3;
$pale-violetred: #db4373;
$wild-watermelon: #ff5372;
$orange: #F5A623;
$orange-light: #FCE4BE;
$caribbean-green: #06c876;
$valhalla: #26185a;
$light-grey: #ebedf8;
$silver: #ced1e3;
$gray: #637280;
$dark-gray: #333333;
$color-text-light: #ffffff;
$color-text-grayed: #767676;
$color-text-grayed-dark: #484848;
$color-text-grayed-light: #72808c;
$color-background-grayed-light-45: rgba(234, 237, 243, 0.45);

$color-theme-1: #0017bb;
$color-theme-2: #db4373;
$color-background-light: #ffffff;
$color-background-grayed: #ededf8;

$gray-gradient: #fbfbfc;
$save-background: #f7f8fc;


$primary-button-active: #C43C66;

$select-background-color: #fafbfc;

$input-disabled-border: #E4E7F1;
$input-disabled-color: #CAD1E1;
$input-disabled-background-color: rgba(228, 231, 241, 0.5);
$input-phone-number-language-color: #fdfdfd;

$calendar_color_input: #c0c4d7;
$search-shadow: #c0c4d7;
$search-height: 60px;
$suggestion-hover: #F2F3FD;
$suggestions-shadow: #4da1ff;
$suggestion-item-color: #1b3c58;
$filter-height: 60px;
$clear-filter-color: #A1AAB3;
$filters-elements-shadow: #353c44;

$oval-button-shadow: #4c0231;

$hover-status-tooltip: #f7f8fa;
$shadow-color-tooltip: #101dbf;

$tab-height: 70px;

$switch-profile-header-background: #E3E9EF;
$switch-profile-select-hover: #F5F7F9;

$separator_color: #c1c7cc;
$linkedin_color: #0B7DB9;
$linkedin_shadow: rgba(16, 144, 191, 0.11);


$notification-separator-color: rgba(#C0C4D7, 0.25);

$notification-date-color: #A3A8C4;

$message-height-padding-bottom: 2px;
$error-message-color: #FF5252;
$error-message-background-color: rgba(255, 82, 82, 0.1);

$info-profile-empty: #BAC0C6;
$info-profile-label-empty: #B1B8BF;
$info-profile-numbers: #A9B4BF;

$avatar-upload-empty-border: #C0C4D7;

$contacts-note-hover-bck-color: #F7F8FA;

$background-header-buy-solutions: #F6F6FA;
$blur-border: #DFE1EB;
$solution-item-border: #DFE1E9;

$total-background-color: #F6F6FA;

$award-popOver-hover: #F5F7F9;

$rewards-border-color: #DFE1E9;
$rewards-status-pending-color: #979AAA;
$rewards-status-color: #C0C4D7;


$delete-wrapper-color: #858F98;
$user-profile-border-color: #DDDDED;
$edit-button-color: #B1B8BF;
$edit-button-color-hover: #F3F5FA;
$user-profile-image-color: #C0C4D7;

$bank-details-border-color: #E7EAED;

$zindex-modal-backdrop: 2040;
$zindex-modal: 2050;
$layout-image-path: '../assets/images';
