@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';
@import 'node_modules/bootstrap/scss/buttons';
@import 'node_modules/bootstrap/scss/dropdown';
@import "../styles/_inocrowd-variables.scss";

.user-widget {
  display: flex;
  align-items: center;

  .user-button {
    display: flex;
    align-items: center;
    opacity: 0.9;
    color: $gray;
    font-size: 0.9375rem;
    padding: 0 0 0 20px;


    .user-wrapper {
      .fullName {
        opacity: 0.9;
        color: #637280;
        font-size: 15px;
        font-weight: 500;
        text-align: left;

        max-width: 185px;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .profile {
        display: flex;
        align-items: center;

        .profileName {
          opacity: 0.7;
          color: $gray;
          font-size: 0.9375rem;
          line-height: 1.5;
          text-align: left;

          text-overflow: ellipsis;
          max-width: 120px;
          overflow: hidden;
        }

        .selectedProfile {
          margin-left: 3px;
          padding: 4px 10px;
          border-radius: 3px;
          background-color: #E3E9EF;
          color: #637280;
          font-size: 14px;
          line-height: 1;
          font-weight: 500;
        }
      }
    }

    .userName {
      opacity: 0.9;
      color: #637280;
      font-size: 15px;
      font-weight: 500;
      text-align: left;

      max-width: 185px;
      text-overflow: ellipsis;
      overflow: hidden;
    }


    &:hover,
    &:focus {
      text-decoration: none;
      color: $dark-gray;
    }

    &::after {
      content: 'keyboard_arrow_down';
      font-family: 'Material Icons';
      border: 0;
      width: fit-content;
      height: fit-content;
      font-size: 1.25rem;
    }
  }

  .collapse-switch-user-wrapper {
    .switch-user-wrapper {
      display: flex;
      opacity: 0.9;
      color: $dark-gray;
      font-size: 0.875rem;
      font-weight: 600;
      cursor: pointer;
      padding: 20px 10px 10px;

      .option-icon {
        width: 20px;
        margin-right: 10px;
        display: flex;
        align-items: center;

        .switch-user {
          g {
            fill: $dark-gray;
          }
        }
      }
    }
  }

  .collapse-wrapper {
    padding: 20px 10px 20px;
    opacity: 0.9;
    color: $gray;
    font-size: 1rem;
    letter-spacing: 0.11px;
    line-height: 1.5625rem;


    &.switch-user-option {
      padding-top: 5px;
    }

    .switch-user-title {
      color: $gray;
      font-size: 0.9375rem;
      font-weight: 500;
      letter-spacing: 0.09px;

    }

    .switch-user-divider {
      height: 0;
      overflow: hidden;
      border-top: 1px solid #e9ecef;
      margin-top: 5px;
      margin-bottom: 15px;
    }

    .collapse-option {
      display: flex;
      padding-bottom: 20px;
      cursor: pointer;

      &.disabled {
        color: $silver;

        .option-icon {
          g {
            fill: $silver;
          }
        }
      }

      .option-icon {
        width: 20px;
        margin-right: 10px;
        display: flex;
        text-align: center;
        align-items: center;
      }

      .option-wrapper-switch-user {
        width: 100%;
        display: flex;
        padding: 0 5px;

        .option-icon {
          width: 28px;

          .picture-wrapper {
            margin-right: 0;
          }

          img {
            border-radius: 50%;
          }
        }
      }

      &:hover:not(.disabled) {
        color: $dark-gray;
      }
    }
  }

  .user-dropdown {
    position: relative;

    &.btn-group .picture-wrapper {
      margin-right: 20px;
    }

    .user-dropdown-menu {
      min-width: 230px;
      border: 0;
      padding: 0;
      border-radius: 4px;
      box-shadow: 0 -1px 15px 2px rgba($gray, 0.2);
      overflow: hidden;
      @include media-breakpoint-down(md) {
        display: none;
      }

      .navigation-list-wrapper {
        margin-top: 10px;
        margin-bottom: 10px;
      }

      .switch-user-list-wrapper {
        max-height: 500px;
        overflow-y: auto;
        padding-top: 10px;
        padding-bottom: 10px;

        .seeker, .solver {
          max-width: 155px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .dropdown-header {
        display: flex;
        align-items: center;
        background-color: $switch-profile-header-background;
        height: 48px;
        font-size: 0.9375rem;
        position: relative;
        color: $gray;
        font-weight: 500;
        padding-left: 15px;

        .switch-user-wrapper {
          display: flex;
          opacity: 0.9;
          color: $dark-gray;
          font-size: 0.875rem;
          font-weight: 600;
          cursor: pointer;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);

          .option-icon {
            width: 20px;
            margin-right: 10px;
            display: flex;
            text-align: center;
            align-items: center;

            .switch-user {
              g {
                fill: $dark-gray;
              }
            }
          }
        }

        .profile-wrapper {
          display: flex;
          flex-direction: column;

          .profileName {
            opacity: 0.7;
            color: #637280;
            font-size: 14px;
            line-height: 1.5;
            font-weight: 400;

            max-width: 150px;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .selectedProfile {
            margin-top: -2.5px;
            margin-left: 10px;
            color: #637280;
            font-size: 14px;
            font-weight: 500;
          }
        }

        .picture-wrapper {
          background-color: white;
          margin-right: 10px;
          height: 32px;
          width: 32px;

          .userName {
            max-width: 150px;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          img {
            height: 32px;
            width: 32px;
          }
        }
      }

      .user-dropdown-divider-title {
        color: $gray;
        font-size: 0.8125rem;
        font-weight: 500;
        letter-spacing: 0.09px;
        padding: 5px 0 0 18px;
      }

      .dropdown-divider {
        margin: 1px 16px 7.5px;
      }

      .user-dropdown-menu-option {
        display: flex;
        cursor: pointer;
        font-size: 0.875rem;
        line-height: 2.125rem;
        color: $gray;
        letter-spacing: 0.1px;
        padding: 0 10px;
        background-color: white;
        transition: color .2s ease-out;

        &:disabled {
          color: $silver;
          cursor: default;

          .option-wrapper {
            cursor: default;

            &:hover,
            &:active {
              background-color: inherit;
            }

            g {
              fill: $silver;
            }
          }
        }

        &:hover:not(disabled),
        &:active:not(disabled) {
          transition: color .2s ease-in;
        }

        &:focus {
          outline: none;
        }

        .option-wrapper {
          width: 100%;
          display: flex;
          padding: 0 10px;
          transition: background-color .2s ease-out;
          user-select: none;

          &:hover,
          &:active {
            background-color: $switch-profile-select-hover;
          }

          &.option-wrapper-switch-user {
            padding: 0 5px;

            .option-icon {
              width: 28px;

              .picture-wrapper {
                margin-right: 0;
              }

              img {
                border-radius: 50%;
              }
            }
          }

          .option-icon {
            width: 20px;
            margin-right: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
          }

        }
      }
    }
  }

}

@include media-breakpoint-down(md) {
  .user-widget {
    width: 100%;
    background-color: white;

    .user-widget-mobile {
      padding: 30px 0;
      transition: padding-top .2s ease, padding-bottom .2s ease;

      &.hide-profile {
        border: none;
      }

      .user-button {
        font-size: 1rem;
        line-height: 1.5625rem;
        padding-right: 0;
        padding-left: 0;

        &.expanded::after {
          content: 'keyboard_arrow_up';
        }

        .picture-wrapper {
          width: 40px;
          height: 40px;
          line-height: 1.875rem;
          margin-right: 20px;
        }
      }
    }
  }
}
