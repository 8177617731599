// Required
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/mixins';
@import 'styles/_inocrowd-variables.scss';
@import 'node_modules/bootstrap/scss/reboot';
@import 'node_modules/bootstrap/scss/transitions';
@import 'node_modules/bootstrap/scss/grid';
@import 'node_modules/bootstrap/scss/forms';
@import 'node_modules/bootstrap/scss/breadcrumb';
@import 'node_modules/bootstrap/scss/alert';
@import 'node_modules/bootstrap/scss/tooltip';
@import 'node_modules/bootstrap/scss/utilities';
@import 'node_modules/react-toastify/scss/main';
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

html {
  height: 100vh;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.modal-open {
    .Toastify__toast-container {
      z-index: 9999;
      pointer-events: none;

      .Toastify__toast {
        margin-bottom: 10px;
        border-radius: 4px;
        pointer-events: auto;
        width: auto;
        @include media-breakpoint-down(xs) {
          margin-left: 10px;
          margin-right: 10px;
        }

        .Toastify__toast-body {
          border-radius: 2px;
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }
    @include media-breakpoint-down(xs) {
      .Toastify__toast-container--top-center {
        top: 20px;
      }
    }
  }
}

.Toastify {
  .Toastify__toast-container {
    padding: 0;
    text-align: center;
    left: 50%;
    z-index: 2000;


    &.Toastify__simple { //sign-pages
      top: 20px;
      z-index: 9999;
      pointer-events: none;

      .Toastify__toast {
        margin-bottom: 10px;
        border-radius: 4px;
        pointer-events: auto;
        width: auto;
        @include media-breakpoint-down(xs) {
          margin-left: 10px;
          margin-right: 10px;
        }

        .Toastify__toast-body {
          border-radius: 2px;
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }

    .Toastify__toast {
      font-size: 0.9375rem;
      font-weight: 500;
      letter-spacing: 0.1px;
      min-height: auto;
      border: none;
      box-shadow: none;
      background-color: white;
      position: relative;
      font-family: $font-family-base;
      padding: 12px;
      margin-bottom: 3px;
      width: 100%;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }


      &.Toastify__toast--success {
        color: $alert-success-text-color;

        &:before {
          background-color: $alert-success-background-color;
        }
      }

      &.Toastify__toast--error {
        color: $alert-error-text-color;

        &:before {
          background-color: $alert-error-background-color;
        }
      }

      &.Toastify__toast--info {
        color: $alert-info-text-color;
        background-color: $alert-info-background-color;

      }
    }
  }


  .Toastify__toast-container--top-center {
    top: $header-height;
    transform: none;
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-left: auto;
    left: auto;
  }


  @include media-breakpoint-down(md) {
    .Toastify__toast-container--top-center {
      top: $header-mobile-height;
    }
  }
}


.app-shell .freeze {
  @include media-breakpoint-down(md) {
    height: 100vh;
    overflow: hidden;
  }
}

.page-wrapper {
  width: 100vw;
  margin-top: $header-height;
  min-height: calc(100vh - #{$header-height} - #{$footer-height});
  background: linear-gradient(180deg, white 0%, $gray-gradient 100%);

  @include media-breakpoint-down(md) {
    margin-top: $header-mobile-height;
    // keep the footer at the end
    min-height: calc(100vh - #{$header-mobile-height} - #{$footer-md-height});
  }
}

.ino-container {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-right: auto;
  margin-left: auto;

  @include media-breakpoint-up(lg) {
    padding-right: 2.75rem;
    padding-left: 2.75rem;
    max-width: 1140px;
  }

  @include media-breakpoint-up(xl) {
    max-width: 1600px;
  }
}

.inocrowd-table {
  border: 1px solid #DFE1E9;
  border-radius: 4px;
  width: 100%;

  @include media-breakpoint-down(md) {
    border: 0;
    border-radius: 0;
    width: 100vw;
    margin-left: -1rem;
    padding-left: 1rem;
  }

  table {
    width: 100%;
    @include media-breakpoint-down(md) {
      display: inline-table;
      margin-right: 1rem;
      border: 1px solid #DFE1E9;
    }

    tr {
      height: 56px;
      border-bottom: 1px solid #DFE1E9;
      color: $dark-gray;
      font-size: 0.9375rem;
      letter-spacing: 0.1px;
      line-height: 1.4375rem;

      td,
      th {
        padding: 0 25px;
      }

      th {
        color: $dark-gray;
        font-size: 0.8125rem;
        font-weight: 500;
        letter-spacing: 0.09px;
        line-height: 1.25rem;
      }
    }

    tr:last-child:not(:first-child) {
      border: none;
    }
  }

  @include media-breakpoint-down(md) {
    + .pagination-row {
      border: 0 !important;
    }
  }
}

.fadeTransition-enter {
  opacity: 0;
  z-index: 1;
}

.fadeTransition-enter.fadeTransition-enter-active {
  opacity: 1;
  transition: opacity 450ms ease-in;
}
