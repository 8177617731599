@import '../styles/_inocrowd-variables';
@import '../styles/_flexboxgrid';
@import '../styles/_mixins';

.site-navbar {
  user-select: none;
  background-color: $color-background-light;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 56px;
  z-index: 2000;
  border-bottom: 1px solid $color-background-grayed;

  > .container,
  > .container > .row {
    height: 100%;
  }

  .logo-wrapper {
    line-height: 1;

    .logo {
      height: 20px;
    }
  }

  .nav-menu-button-wrapper,
  .nav-menu-button {
    display: none;
  }

  .nav-notifications-profile {
    display: flex;
    align-items: center;
  }
}

@media (max-width: 61.999em) {
  .site-navbar {
    .nav-wrapper {
      position: fixed;
      top: 0;
      bottom: 0;
      right: 100%;
      width: 100vw;
      background-color: $color-background-light;
      padding-top: 0;
      margin-top: 56px;
      padding-left: 1rem;
      padding-right: 1rem;
      @include transition(right 0.22s ease-in-out);
      overflow-y: auto;
      z-index: -1;


      &.open {
        right: 0;
      }

      .nav-list {
        text-align: left;
        margin-top: 25px;

        .nav-item {
          &:not(:first-child) {
            margin-top: 20px;
          }

          a, .label {
            display: inline-block;
            position: relative;
            padding: 5px;
            left: -5px;
            font-size: 1rem;
            letter-spacing: 0.21px;
            line-height: 1;
            text-decoration: none;
            color: $dark-gray;

            &.active,
            &.hasActiveChild,
            &.isOpen {
              color: $color-theme-1;
            }
          }

          .label{
            background: transparent;
            border: none;
            cursor: pointer;

            &:after{
              content: '';
              display: inline-block;
              margin-left: 7px;
              width: 11px;
              height: 10px;
              vertical-align: middle;
              background: url("../assets/images/angle.svg") no-repeat center;
              background-size: contain;
              transition: all 500ms;
              transform: rotateX(0deg);
            }

            &.isOpen:after{
              transform: rotateX(180deg);
            }
          }

          .dropdown-nav-wrapper{
            margin-top: 10px;
            display: none;

            &.isOpen{
              display: block;
            }

            .dropdown-nav{
              text-align: left;
              padding-left: 15px;

              .nav-item{
                margin-bottom: 5px;
                margin-top: 0;

                a{
                  padding: 5px;
                  left: -5px;
                  position: relative;
                  display: inline-block;
                  transition: all 500ms;
                  font-size: 0.875rem;

                  &:hover{
                    color: $color-theme-1;
                  }
                }
              }
            }
          }
        }
      }

      .nav-separator-horizontal-wrapper {
        width: 100%;
        background-color: white;
        margin-top: 25px;

        .nav-separator-horizontal {
          max-height: 500px;
          border-top: 1px solid $silver;
          transition: max-height 0.4s ease;
        }
      }
    }

    .nav-menu-button-wrapper {
      display: flex;
      align-items: center;
      margin-top: 3px;

      &.open{
        margin-top: 2px;
      }

      .nav-menu-button {
        margin: 0;
        padding: 0;
        border: none;
        background: none;
        display: block;
        width: 24px;
        height: 20px; //24px
        @include transform(rotate(0deg));
        @include transition(all 0.5s ease-in-out);
        cursor: pointer;

        span {
          display: block;
          position: absolute;
          height: 2px;
          width: 50%;
          background: $color-text-grayed-light;
          opacity: 1;
          @include transform(rotate(0deg));
          @include transition(all 0.25s ease-in-out);

          &:nth-child(even) {
            left: 50%;
            @include border-radius(0 20px 20px 0);
          }

          &:nth-child(odd) {
            left: 0;
            @include border-radius(20px 0 0 20px);
          }

          &:nth-child(1),
          &:nth-child(2) {
            top: 0;
          }

          &:nth-child(3),
          &:nth-child(4) {
            top: 8px;
          }

          &:nth-child(5),
          &:nth-child(6) {
            top: 16px;
          }
        }

        &.open {
          span {
            &:nth-child(1),
            &:nth-child(6) {
              @include transform(rotate(45deg));
            }

            &:nth-child(2),
            &:nth-child(5) {
              @include transform(rotate(-45deg));
            }

            &:nth-child(1) {
              left: 0;
              top: 5px;
            }

            &:nth-child(2) {
              left: calc(50% - 4px);
              top: 5px;
            }

            &:nth-child(3) {
              left: -50%;
              opacity: 0;
            }

            &:nth-child(4) {
              left: 100%;
              opacity: 0;
            }

            &:nth-child(5) {
              left: 0;
              top: 13px;
            }

            &:nth-child(6) {
              left: calc(50% - 4px);
              top: 13px;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 62em) {
  .site-navbar {
    height: 70px;

    .logo-wrapper .logo {
      height: 25px;
    }

    .nav-notifications-profile .nav-separator {
      margin-left: 25px;
      margin-right: 5px;
      box-sizing: border-box;
      height: 56px;
      border: 1px solid $light-grey;
    }

    .nav-wrapper .nav-list {
      display: flex;
      > .nav-item {
        display: inline-block;
        margin-right: 15px;
        position: relative;

        a, button.label {
          font-size: 0.938rem;
          font-weight: 400;
          letter-spacing: 0.2px;
          line-height: 1.533;
          color: $color-text-grayed-light;
          text-decoration: none;
          vertical-align: middle;
          @include transition(all 0.3s ease-in-out);

          &.active,
          &.hasActiveChild {
            color: $color-theme-1;
          }
        }

        button.label{
          padding: 0;
          background: transparent;
          border: none;
          cursor: pointer;
        }

        &.button a {
          padding: 8px 18px;
          color: $color-theme-2;
          border: 2px solid $color-theme-2;
          @include border-radius(4px);
          @include transition(all 500ms ease-in-out);
          background: transparent;

          &:hover {
            color: $color-text-light;
            background: $color-theme-2;
          }
        }

        &:not(.button) > a:after,
        &:not(.button) > div > button.label:after {
          content: '';
          position: absolute;
          bottom: -24px;
          width: calc(100% + 20px);
          left: 50%;
          @include transform(translateX(-50%));
          height: 2px;
          background-color: transparent;
          @include transition(all 0.3s ease-in-out);
        }

        &:not(.button) > a:hover:after,
        &:not(.button) > div > button.label:hover:after {
          background-color: $color-text-grayed-light;
        }

        &:not(.button) > a.active:after,
        &:not(.button) > div > button.label.isOpen:after {
          background-color: $color-theme-1;
        }

        &:not(.button) > div > button.label.isOpen {
          color: $color-theme-1;
        }

        &:not(.button) > a.hasActiveChild:after {
          background-color: $color-theme-1;
        }

        .dropdown-nav-wrapper{
          position: absolute;
          top: calc(100% + 25px);
          left: 50%;
          transform: translateX(-50%);
          margin-top: 25px;
          display: none;
          box-shadow: 0 2px 6px 0 rgba(192,196,215,.4);

          &.isOpen{
            display: block;
          }

          .dropdown-nav{
            min-width: 120px;
            width: max-content;
            text-align: left;
            background-color: #ffffff;
            padding: 10px 7px;

            &:before{
              content: "";
              position: absolute;
              top: -3px;
              left: 50%;
              width: 21px;
              height: 21px;
              transform: rotate(45deg) translateX(-50%);
              box-shadow: 0 2px 6px 0 rgba(192,196,215,.4);
              z-index: -1;
              background: #ffffff;
            }

            .nav-item{
              margin-bottom: 5px;

              a{
                padding: 5px 13px;
                display: block;
                transition: all 500ms;

                &:hover{
                  color: $color-theme-1;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 72em) {
  .site-navbar .nav-wrapper .nav-list > .nav-item {
    margin-right: 40px;
  }
}
