@import '../styles/_inocrowd-variables';
@import "node_modules/bootstrap/scss/mixins";

.notifications-wrapper {
  .message-notifications {
    display: flex;
    position: relative;
    margin-top: 2px;

    .unread {
      position: absolute;
      height: 12px;
      width: 12px;
      background-color: $pale-violetred;
      border: 2px solid $white;
      border-radius: 50%;
      right: -6px;
      top: -6px;
    }
  }
}

@include media-breakpoint-down(md) {
  .notifications-wrapper {
    .message-notifications {
      margin-right: 20px;
    }
  }
}
