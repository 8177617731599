@import "../../styles/_inocrowd-variables.scss";

.picture-wrapper {
  line-height: 2.5rem;
  font-size: 0.875rem;
  font-weight: bold;
  border-radius: 50%;
  margin-right: 10px;
  position: relative;

  &.emptyPicture {
    color: $orange;
    background-color: $orange-light;
  }

  .initials {
    user-select: none;
    text-transform: uppercase;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .img {
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
}

.double-avatar-wrapper{
  position: relative;
  .double-avatar{
    position: absolute;
    top: 25px;
    right: -11px;
    box-shadow: 0 2px 4px 0 rgba(192,196,215,0.31);
  }
}
