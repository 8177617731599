@import "../../styles/_inocrowd-variables.scss";

.switch-profile-modal{
  max-width: 580px;

  .modal-body{
    padding: 38px 48px;

    .modal-content{
      margin-left: 0;
      margin-right: 0;
    }
  }

  .title{
    color: #333333;
    font-size: 22px;
    font-weight: 400;
    letter-spacing: 0.15px;
    line-height: 33px;
    margin-bottom: 26px;
  }

  .description{
    color: rgba(99, 114, 128, 0.8);
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.11px;
    line-height: 30px;
    margin-bottom: 25px;
  }

  .check-box{
    margin-bottom: 43px;
  }

  .submission{
    text-align: right;

    .secondary-button{
      border-width: 1px;
      margin-right: 10px;
      min-width: 125px;
    }

    .primary-button{
      border-width: 2px;
      min-width: 125px;
    }
  }
}

@media (max-width: 600px){
  .switch-profile-modal{
    .submission{

      .secondary-button{
        min-width: 100px;
      }

      .primary-button{
        min-width: 100px;
      }
    }
  }
}
