@import '../../styles/_inocrowd-variables.scss';
@import 'node_modules/react-select/scss/select';
@import 'node_modules/react-select/scss/components';
@import 'node_modules/bootstrap/scss/mixins';
@import 'node_modules/bootstrap/scss/custom-forms';

%input-label {
  color: $gray;
  /*  opacity: 0.8;*/
  font-size: 0.81rem;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 5px;

  img {
    padding-left: 10px;
  }
}

%input-label-max-length {
  color: $gray;
  font-size: 0.6875rem;
}

%input-message {
  font-size: 0.75rem;
  line-height: 15px;
  margin-top: 5px;
}

label.input-label {
  color: $gray;
  font-weight: 500;
  font-size: 0.83rem;
}

// TEXT BOX
.text-box {
  position: relative;
  margin-bottom: 18px;
  text-align: start;

  .text-box-input-wrapper {
    position: relative;
    display: flex;
    flex-direction: row-reverse;

    .text-box-icon-wrapper {
      display: flex;
      width: 42px;
      height: 42px;
      background-color: white;
      border: 1px solid $silver;
      border-right: 0;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      text-align: center;
      color: $gray;

      .text-box-icon {
        line-height: 40px;
        font-size: 20px;
        margin: auto;

        &.clickable {
          cursor: pointer;
        }
      }
    }
  }

  &.has-icon {
    .text-box-input-wrapper {
      .text-box-input {
        width: calc(100% - 42px);
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  .text-box-label-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .text-box-label {
    @extend %input-label;
  }

  .text-box-label-max-length {
    @extend %input-label-max-length;
  }

  .text-box-input {
    width: 100%;
    color: $gray;
    font-size: 0.875rem;
    line-height: 18px;
    padding: 11px 15px;
    border: 1px solid $silver;
    border-radius: 4px;
    outline: none;

    &:focus {
      border-color: $medium-blue;
    }

    &:focus + .text-box-icon-wrapper {
      color: $medium-blue;
      border-color: $medium-blue;
    }

    &::placeholder {
      color: $valhalla;
      opacity: 0.3;
    }
  }

  .password-icon {
    position: absolute;
    right: 15px;
    font-size: 1.375rem;
    line-height: 2.5rem;
    vertical-align: middle;
    cursor: pointer;
    color: $gray;
  }

  .text-box-validation-icon {
    position: absolute;
    margin: 10px;
    right: 5px;
  }

  .text-box-message {
    @extend %input-message;
    font-size: 0.6875rem;
    position: absolute;
    line-height: 0.6875rem;
    margin-top: 4px;
  }
}

.text-box.disabled {
  .text-box-input {
    background-color: $input-disabled-background-color;

    &:focus + .text-box-icon-wrapper {
      color: $input-disabled-color;
      border: 1px solid $input-disabled-border;
    }
  }

  .text-box-icon-wrapper {
    color: $input-disabled-color;
    background-color: $input-disabled-background-color;
  }
}

.text-box.success {
  .text-box-input {
    border-color: $caribbean-green;

    &:focus + .text-box-icon-wrapper {
      color: $caribbean-green;
      border-color: $caribbean-green;
    }
  }

  .text-box-icon-wrapper {
    color: $caribbean-green;
    border-color: $caribbean-green;
  }

  .text-box-validation-icon {
    background-color: $caribbean-green;
    color: $white;
    border-radius: 50%;
    font-size: 0.88rem;
    padding: 3px;
  }

  .text-box-message {
    color: $caribbean-green;
  }
}

.text-box.error {
  .text-box-input {
    border-color: $wild-watermelon;

    &:focus + .text-box-icon-wrapper {
      color: $wild-watermelon;
      border-color: $wild-watermelon;
    }
  }

  .text-box-icon-wrapper {
    color: $wild-watermelon;
    border-color: $wild-watermelon;
  }

  .text-box-message {
    color: $wild-watermelon;
  }
}

// TEXT BOX DROPDOWN
.text-box-dropdown {
  .text-box-label {
    @extend %input-label;
  }

  .text-box-dropdown-wrapper {
    display: flex;

    .text-box {
      flex-grow: 1;
      margin-bottom: 0;

      .text-box-input {
        border-right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    .Select {
      font-size: 0.8125rem;
      font-weight: 500;

      .Select-control {
        height: 42px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      .Select-placeholder,
      .Select-value {
        text-align: center;
        padding-right: 25px;
        line-height: 42px;
      }

      &.is-focused {
        .Select-control {
          border-color: $silver;
        }
      }
    }
  }

  &.focused {
    .text-box-dropdown-wrapper {
      .Select {
        .Select-control {
          border-color: $medium-blue;
        }
      }
    }
  }

  &.error {
    .text-box-dropdown-wrapper {
      .Select {
        .Select-control {
          border-color: $wild-watermelon;
        }
      }
    }
  }
}

// TEXT AREA
.text-area {
  position: relative;
  margin-bottom: 18px;
  text-align: start;

  .text-area-label-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .text-area-label {
    @extend %input-label;
  }

  .text-area-label-max-length {
    @extend %input-label-max-length;
  }

  .text-area-input {
    width: 100%;
    color: $gray;
    font-size: 0.88rem;
    line-height: 18px;
    padding: 11px 15px;
    border: 1px solid $silver;
    border-radius: 4px;
    outline: none;
    resize: none;

    &:focus {
      border-color: $medium-blue;
    }

    &::placeholder {
      color: $valhalla;
      opacity: 0.3;
    }
  }

  .text-area-icon {
    position: absolute;
    margin: 10px;
    right: 5px;
  }

  .text-area-message {
    @extend %input-message;
    font-size: 0.6875rem;
    position: absolute;
    line-height: 0.6875rem;
    margin-top: 4px;
  }
}

.text-area.success {
  .text-area-input {
    border-color: $caribbean-green;
  }

  .text-area-icon {
    background-color: $caribbean-green;
    color: $white;
    border-radius: 50%;
    font-size: 0.88rem;
    padding: 3px;
  }

  .text-area-message {
    color: $caribbean-green;
  }
}

.text-area.error {
  .text-area-input {
    border-color: $wild-watermelon;
  }

  .text-area-icon {
    font-size: 1.35rem;
    color: $wild-watermelon;
  }

  .text-area-message {
    color: $wild-watermelon;
  }
}

// CHECK BOX
.check-box {
  display: flex;
  width: fit-content;

  .check-box-input-container {
    position: relative;
    align-self: center;
    height: 16px;
    min-width: 16px;
    border: 0.8px solid $silver;
    border-radius: 2.4px;
    background-color: $white;

    .check-box-input {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      opacity: 0;
      z-index: 10;
    }

    .check-box-checkmark {
      display: none;
      position: absolute;
      left: 0;
      color: white;
      font-size: 0.88rem;
      font-weight: 600;
    }

    &:hover {
      border-color: $medium-blue;

      .check-box-checkmark {
        display: inline-block;
        color: $silver;
      }
    }
  }

  .check-box-label {
    label {
      user-select: none;
      cursor: pointer;
      color: $gray;
      font-size: 0.88rem;
      line-height: 16px;
      margin-left: 10px;
      margin-bottom: 0;
    }
  }
}

.check-box.disabled {
  .check-box-input-container {
    background-color: rgba(228, 231, 241, 0.3);
    border-color: $silver;

    .check-box-checkmark {
      display: inline-block;
    }

    .check-box-checkmark {
      display: none;
    }
  }

  .check-box-label {
    label {
      cursor: default;
    }
  }
}

.check-box.checked {
  .check-box-input-container {
    background-color: $medium-blue;
    border-color: $medium-blue;

    .check-box-checkmark {
      display: inline-block;
    }

    &:hover {
      .check-box-checkmark {
        color: $white;
      }
    }
  }

  &.disabled {
    .check-box-input-container {
      background-color: rgba($medium-blue, 0.3);
      border-color: $silver;
    }
  }
}

// RADIO
.radio-group {
  display: flex;
  flex-direction: column;
  color: $gray;
  font-size: 14px;
  line-height: 21px;

  .custom-control-label {
    padding-left: 10px;
  }

  .custom-control-label::before {
    width: 20px;
    height: 20px;
    top: 0;
    background-color: inherit;
    border: 2px solid $silver;
  }

  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none;
  }

  .custom-radio .custom-control-input:checked ~ .custom-control-label::before {
    background-color: $medium-blue;
    border: 2px solid $medium-blue;
  }

  .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    background-image: none;
    border: 3px solid white;
    border-radius: 50%;
    top: 2px;
    left: -22px;
  }

  &.horizontal {
    flex-direction: row;

    .custom-radio + .custom-radio {
      margin-left: 40px;
    }
  }
}

// DROPDOWN
.dropdown-list {
  text-align: start;
  font-size: 0.88rem;
  margin-bottom: 18px;

  .dropdown-list-label {
    @extend %input-label;
  }

  .Select {
    .Select-control {
      line-height: 38px;
      border: 1px solid $silver;
      border-radius: 4px;

      .Select-clear-zone .Select-clear {
        vertical-align: middle;
      }

      .Select-multi-value-wrapper {
        .Select-placeholder {
          line-height: inherit;
          color: $valhalla;
          opacity: 0.3;
        }

        .Select-value {
          line-height: inherit;

          .Select-value-label {
            color: $gray !important;
          }
        }

        .Select-input {
          height: 100%;
        }
      }

      .dropdown-list-icon {
        font-size: 1.3rem;
        vertical-align: middle;
        color: $gray;
      }
    }

    .Select-menu-outer {
      margin-top: 7px;
      border: none;
      border-radius: 4px;
      box-shadow: 0 7px 20px 0 rgba(77, 161, 255, 0.15);

      .Select-menu {
        padding: 5px 0;

        .Select-option {
          color: $gray;

          &.is-focused,
          &.is-selected {
            color: $dark-gray;
            background-color: $select-background-color;
          }
        }
      }
    }
  }

  .Select.Select--multi {
    .Select-control {
      line-height: 38px;

      .Select-value {
        border: 0;
        border-radius: 16px;
        margin-left: 7px;
        margin-top: 5px;
        margin-bottom: 5px;
        line-height: 28px;
        vertical-align: middle;
        background-color: #f7f8fa;
        padding-right: 16px;

        .Select-value-icon {
          float: right;
          border: 0;
          padding: 0;
          border-radius: 0;
          visibility: hidden;

          &:after {
            content: 'close';
            font-family: 'Material Icons';
            visibility: visible;
            vertical-align: middle;
            font-size: 0.95rem;
            font-weight: 600;
            color: $gray;
          }
        }

        .Select-value-label {
          padding: 0;
          border-radius: 0;
          font-size: 0.88rem;
          margin-left: 16px;
        }
      }
    }
  }

  .Select.is-open,
  .Select.is-focused {
    .Select-control {
      border-color: $medium-blue;
    }
  }

  .Select.is-disabled {
    .Select-control {
      line-height: 40px;
      background-color: $input-disabled-background-color;

      .Select-multi-value-wrapper {
        line-height: 38px;
      }
    }
  }

  .dropdown-list-message {
    @extend %input-message;
  }
}

.dropdown-list.success {
  .Select {
    .Select-control {
      border-color: $caribbean-green;
    }
  }
}

.dropdown-list.error {
  .Select {
    .Select-control {
      border-color: $wild-watermelon;
    }
  }

  .dropdown-list-message {
    position: absolute;
    color: $wild-watermelon;
    font-size: 0.6875rem;
    line-height: 0.6875rem;
    margin-top: 4px;
  }
}

.input-button {
  position: relative;

  .text-box {
    i {
      display: none;
      //right: 158px;
    }
  }

  .button-wrapper {
    position: absolute;
    right: 0;
    bottom: 0;

    > button {
      margin: 5px;
      padding: 8px 17px;

      i.text-box-validation-icon {
        position: absolute;
        left: -25px;
        font-size: 1.35rem;
        bottom: 10px;
        color: #ff5372;
      }

      span {
        font-size: 0.8125rem;
        font-weight: 500;
        letter-spacing: 0.09px;
      }
    }
  }

  &.btn-icon {
    .button-wrapper {
      > button {
        padding-left: 38px;

        img {
          position: absolute;
          left: 16px;
        }
      }
    }
  }
}

// LANGUAGE DROPDOWN
.language-dropdown-list {
  text-align: start;
  font-size: 0.88rem;

  .Select {
    .Select-control {
      border-radius: 0;
      border: none;
      background: none;
      line-height: 40px;

      &:hover {
        box-shadow: none;
      }

      .Select-clear-zone .Select-clear {
        vertical-align: middle;
      }

      .Select-multi-value-wrapper {
        .Select-placeholder {
          line-height: inherit;
          color: $valhalla;
          opacity: 0.3;
        }

        .Select-value {
          text-align: right;
          line-height: inherit;
          padding-right: 0;
          padding-left: 0;
          @include media-breakpoint-down(sm) {
            text-align: center;
          }

          .Select-value-label {
            color: $dark-gray;
            font-size: 1.0625rem;
            font-weight: 500;
            position: relative;
            padding-left: 20px;

            &:before {
              content: '';
              position: absolute;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
              background: url('../../assets/images/language.svg') no-repeat center center;
              background-size: contain;
              width: 14px;
              height: 14px;
            }
          }
        }

        .Select-input {
          height: 100%;
        }
      }
    }

    .Select-menu-outer {
      margin-top: 7px;
      border: none;
      border-radius: 4px;
      box-shadow: 0 7px 20px 0 rgba(77, 161, 255, 0.15);

      .Select-menu {
        padding: 5px 0;

        .Select-option {
          color: $gray;

          &.is-focused,
          &.is-selected {
            color: $dark-gray;
            background-color: $select-background-color;
          }
        }
      }
    }
  }

  .Select.is-open,
  .Select.is-focused {
    .Select-control {
      border-color: $medium-blue;
    }
  }
}

//DATEPICKER
.datepicker {
  position: relative;
  margin-bottom: 18px;
  text-align: start;

  .datepicker-input-wrapper {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
  }

  .datepicker-label-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .datepicker-label {
    @extend %input-label;
  }

  .datepicker-max-length {
    @extend %input-label-max-length;
  }

  .datepicker-validation-icon {
    position: absolute;
    margin: 10px;
    bottom: 0px;
    right: 40px;
  }

  .datepicker-message {
    @extend %input-message;
    font-size: 0.6875rem;
    position: absolute;
    line-height: 0.6875rem;
    margin-top: 4px;
  }
}

.datepicker.success {
  .DateInput_input,
  .SingleDatePickerInput_calendarIcon {
    border-color: $caribbean-green !important;
  }

  .datepicker-validation-icon {
    background-color: $caribbean-green;
    color: $white;
    border-radius: 50%;
    font-size: 0.88rem;
    padding: 3px;
  }

  .datepicker-message {
    color: $caribbean-green;
  }
}

.datepicker.error {
  .DateInput_input,
  .SingleDatePickerInput_calendarIcon {
    border-color: $wild-watermelon !important;
  }

  .datepicker-validation-icon {
    font-size: 1.35rem;
    color: $wild-watermelon;
  }

  .datepicker-message {
    color: $wild-watermelon;
  }
}

.datepicker.changed {
  .SingleDatePicker .SingleDatePickerInput .DateInput .DateInput_input {
    color: $gray;
  }
}

.SingleDatePicker {
  .SingleDatePicker_picker {
    z-index: 20;
  }

  .DayPicker__withBorder {
    box-shadow: 0 5px 10px 0 rgba(black, 0.05);
    border-radius: 2px;
  }

  //border
  .SingleDatePickerInput__withBorder {
    border: 0;

    .DateInput_input {
      border: 1px solid $silver;
      border-radius: 4px 0 0 4px;
    }

    .SingleDatePickerInput_calendarIcon {
      outline: none;
      border: 1px solid $silver;
      border-left: 0;
      border-radius: 0 4px 4px 0;
    }
  }

  .SingleDatePickerInput {
    display: flex;
    flex-direction: row-reverse;

    &.SingleDatePickerInput__disabled{
      background-color: #F1F3F8;
      .DateInput_input{
        font-style: normal;
        color: #CAD1E1;
        border: 1px solid #E4E7F1;
      }

      .SingleDatePickerInput_calendarIcon{
        border: 1px solid #E4E7F1;
        border-left: 0;
      }
    }

    .DateInput {
      background: none;

      .DateInput_input {
        color: $calendar_color_input;
        font-size: 0.875rem;
        line-height: 18px;
        padding: 11px 18px;
        outline: none;

        &::placeholder {
          color: $calendar_color_input;
          font-size: 0.875rem;
        }
      }
    }

    .SingleDatePickerInput_calendarIcon {
      padding: 0 10px;
      margin: 0;

      img {
        position: relative;
        top: -2px;
      }
    }

    .DateInput_fang {
      display: none;
    }

    &.SingleDatePickerInput__disabled {
      background-color: $input-disabled-background-color;

      .SingleDatePickerInput_calendarIcon {
        background: $input-disabled-background-color;
      }
    }

    .DateInput_input__disabled {
      background-color: $input-disabled-background-color;
      font-style: normal;
    }
  }

  .CalendarMonth {
    .CalendarMonth_caption {
      padding-top: 17px;
    }
  }

  .CalendarDay {
    font-size: 0.8125rem;
  }

  .CalendarMonth_caption {
    text-transform: capitalize;
  }

  .DateInput_input__focused {
    border: 0;
  }

  .DayPicker_weekHeader {
    ul li small {
      color: $dark-gray;
      font-size: 0.8125rem;
      font-weight: 500;
      letter-spacing: 0.08px;
    }
  }

  .CalendarDay__default {
    position: relative;
    border: none;
    background: none;
    color: $gray;
    z-index: 1;

    &:before {
      content: '';
      position: absolute;
      width: 24px;
      height: 24px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      z-index: -1;
    }

    &:hover,
    &:active {
      color: $gray;

      &:before {
        background-color: rgba($medium-blue, 0.15);
      }
    }
  }

  .CalendarDay__blocked_out_of_range {
    color: rgba($gray, 0.4);
  }

  .CalendarDay__selected {
    border: none;
    color: white;

    &:before {
      background-color: $medium-blue;
    }

    &:hover,
    &:active {
      color: white;

      &:before {
        background-color: $medium-blue;
      }
    }
  }

  .DayPickerNavigation {
    &:before,
    &:after {
      top: 0;
      content: '';
      position: absolute;
      background: white;
      width: 65px;
      height: 60px;
      z-index: -1;
      border-radius: 2px;
    }

    &:before {
      left: 0;
    }

    &:after {
      right: 0;
    }
  }

  .DayPickerNavigation_button__horizontalDefault {
    top: 13px;
  }

  .DayPicker_transitionContainer {
    height: 250px !important;
  }

  .CalendarMonth_table {
    margin-top: 11px;

    tr td {
      height: 25px !important;
    }
  }
}

//TIMEPICKER
.timepicker {
  position: relative;
  margin-bottom: 18px;
  text-align: start;

  .timepicker-label-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .timepicker-label {
    @extend %input-label;
  }

  .timepicker-control-wrapper {
    width: 100%;

    .rc-time-picker-input {
      height: 42px;
      padding: 0 18px;
      color: $gray;
      font-size: 0.875rem;
      border: 1px solid $silver;

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: $valhalla;
        opacity: 0.3;
      }

      &:disabled {
        background: $input-disabled-background-color;
        cursor: pointer;
      }
    }

    .timepicker-icon {
      display: flex;
      justify-content: center;
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 40px;
      border-left: 1px solid $silver;
    }

    .timepicker-clear {
      position: absolute;
      top: 0;
      right: 50px;
      height: 100%;
      cursor: pointer;

      i {
        vertical-align: middle;
        line-height: 42px;
        font-size: 18px;
        color: #818D98;
      }
    }
  }
}

.timepicker.error {
  .timepicker-control-wrapper {
    width: 100%;

    .rc-time-picker-input,
    .timepicker-icon {
      border-color: $wild-watermelon;
    }
  }
}

.rc-time-picker-panel {
  max-width: 155px;
  z-index: 1000;

  .rc-time-picker-panel-inner {
    top: 56px;
    overflow: hidden;

    box-shadow: 0 3px 7px 0 rgba(0,0,0,0.03), 0 6px 10px 5px rgba(192,196,215,0.15);
    border-radius: 2px;
    border: none;

    .rc-time-picker-panel-input-wrap {
      display: none;
    }

    .rc-time-picker-panel-combobox {
      .rc-time-picker-panel-select {
        width: 75px;

        li {
          padding: 0;
          text-align: center;
          height: 28px;
          line-height: 28px;
          color: $gray;
          font-size: 13px;
          letter-spacing: 0.08px;

          &.rc-time-picker-panel-select-option-selected {
            background-color: #F2F3FB;
            color: $medium-blue;
            font-weight: normal;
          }

          &:focus {
            outline: none;
          }

          &:hover {
            background-color: #F2F3FB;
          }
        }
      }
    }
  }
}

//TOOLTIP
.helpTooltip {
  &.tooltip {
    &.show {
      opacity: 1;
    }

    .tooltip-inner {
      padding: 15px 15px 15px 5px;
      max-width: 500px;
      background-color: white;
      box-shadow: 0 2px 10px 0 rgba($gray, 0.2);

      span {
        color: $gray;
        font-size: 0.875rem;
        line-height: 21px;
        text-align: left;

        a {
          text-decoration: none;
          color: $medium-blue;
          font-weight: 600;
        }

        ul {
          margin-bottom: 0;
        }
      }
    }

    &.popular-subject .tooltip-inner,
    &.solvers-available .tooltip-inner {
      max-width: 200px;
      box-shadow: 0 0 12px 0 rgba($gray, 0.2);

      span {
        font-size: 13px;
        line-height: 20px;
        text-align: left;
      }
    }

    .arrow {
      top: 20px !important;

      &:before {
        border-right-color: white !important;
      }
    }

    &.popular-subject .arrow,
    &.solvers-available .arrow {
      top: 0 !important;

      &:before {
        border-right-color: transparent !important;
        border-bottom-color: white !important;
      }
    }
  }
}

//SEARCH-BOX
.search-box {
  position: relative;
  text-align: start;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 3px 7px 0 rgba(white, 0.03), 0 6px 10px 5px rgba($search-shadow, 0.15);

  .search-box-input-wrapper {
    width: calc(100% - 65px);
    position: relative;
    display: flex;
    height: $search-height;
    background-color: white;
    border-radius: 4px;

    .search-box-icon-wrapper {
      width: 65px;
      height: 60px;
      text-align: center;
      color: $gray;
      position: relative;
      flex-shrink: 0;

      img {
        height: 18px;
        width: 18px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .search-box-label-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .search-box-input {
      width: 100%;
      color: $gray;
      font-size: 1rem;
      line-height: 1.5625rem;
      outline: none;
      letter-spacing: 0.11px;
      border: none;

      &::placeholder {
        opacity: 0.6;
      }
    }
  }
}
