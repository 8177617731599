@import 'node_modules/bootstrap/scss/mixins';
@import '../../styles/_inocrowd-variables.scss';
@import 'node_modules/bootstrap/scss/modal';


.modal-backdrop {
  opacity: 0.4 !important;
}


.modal-dialog {
  max-width: 540px;
  @include media-breakpoint-down(xs) {
    margin: 0;
  }
}


.modal-content {
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 2px 20px 0 rgba(black, 0.05);
  border: none;
  margin-left: 20px;
  margin-right: 20px;
  @include media-breakpoint-down(xs) {
    margin-left: 0;
    margin-right: 0;
    min-height: 100vh;
  }
}

.modal-body {
  padding-left: 30px;
  padding-right: 30px;
  @include media-breakpoint-down(xs) {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.modal-close {
  cursor: pointer;
  position: absolute;
  top: 45px;
  right: 45px;
  @include media-breakpoint-down(xs) {
    top: 15px;
    right: 15px;
    z-index: $zindex-modal;
  }
}
