// Positioning
// --------------------------
@mixin transform($val) {
    -webkit-transform: $val;
    -moz-transform: $val;
    -ms-transform: $val;
    -o-transform: $val;
    transform: $val;
  }

  @mixin transform-origin($val) {
    -webkit-transform-origin: $val;
    -moz-transform-origin: $val;
    -ms-transform-origin: $val;
    -o-transform-origin: $val;
    transform-origin: $val;
  }

  // Flexbox
  // --------------------------
  @mixin flexbox() {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
  }

  @mixin inline-flexbox(){
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }

  @mixin flex($val) {
    -webkit-box-flex: $val;
    -moz-box-flex: $val;
    -webkit-flex: $val;
    -ms-flex: $val;
    flex: $val;
  }

  @mixin flex($values) {
    -webkit-flex: 0 1 auto;
    -moz-flex: 0 1 auto;
    flex: 0 1 auto;
  }

  @mixin flex-basis($val) {
    -webkit-flex-basis: $val;
    -moz-flex-basis: $val;
    -ms-flex-preferred-size: $val;
    flex-basis: $val;
  }

  @mixin flex-grow($val) {
    -webkit-flex-grow: $val;
    -moz-flex-grow: $val;
    -ms-flex-positive: $val;
    flex-grow: $val;
  }

  @mixin flex-shrink($val) {
    -webkit-flex-shrink: $val;
    -moz-flex-shrink: $val;
    flex-shrink: $val;
  }

  @mixin flex-direction($val) {
    -webkit-flex-direction: $val;
    -moz-flex-direction: $val;
    -ms-flex-direction: $val;
    flex-direction: $val;
  }

  @mixin flex-wrap($val) {
    -webkit-flex-wrap: $val;
    -moz-flex-wrap: $val;
    flex-wrap: $val;
  }

  @mixin justify-content($val) {
    -webkit-justify-content: $val;
    -moz-justify-content: $val;
    justify-content: $val;
  }

  @mixin align-items($val) {
    -webkit-align-items: $val;
    align-items: $val;
  }

  @mixin align-content($val) {
    -webkit-align-content: $val;
    align-content: $val;
  }

  @mixin align-self($val) {
    -webkit-align-self: $val;
    align-self: $val;
  }

  @mixin order($val) {
    -webkit-box-ordinal-group: $val;
    -moz-box-ordinal-group: $val;
    -ms-flex-order: $val;
    -webkit-order: $val;
    order: $val;
  }

  // Limits
  // --------------------------
  @mixin box-sizing($val) {
    -webkit-box-sizing: $val;
    -moz-box-sizing: $val;
    box-sizing: $val;
  }

  @mixin border-radius($val) {
    -webkit-border-radius: $val;
    -moz-border-radius: $val;
    border-radius: $val;
  }

  @mixin overflow-x($val) {
    -ms-overflow-x: $val;
    overflow-x: $val;
  }

  @mixin overflow-y($val) {
    -ms-overflow-y: $val;
    overflow-y: $val;
  }

  // Animations
  // --------------------------
  @mixin animation($val) {
    -webkit-animation: $val;
    -moz-animation: $val;
    -o-animation: $val;
    animation: $val;
  }

  @mixin animation-play-state($val) {
    -webkit-animation-play-state: $val;
    -moz-animation-play-state: $val;
    -o-animation-play-state: $val;
    animation-play-state: $val;
  }

  // Decoration
  // --------------------------
  @mixin appearance($val) {
    -webkit-appearance: $val;
    -moz-appearance: $val;
    appearance: $val;
  }

  @mixin background-clip($val){
    -webkit-background-clip: $val;
    -moz-background-clip: $val;
    background-clip: $val;
  }

  @mixin background-size($val) {
    -webkit-background-size: $val;
    -moz-background-size: $val;
    -o-background-size: $val;
    background-size: $val;
  }

  @mixin background-linear-gradient($val) {
    background-image: -webkit-linear-gradient($val);
    background-image: -moz-linear-gradient($val);
    background-image: -o-linear-gradient($val);
    background-image: linear-gradient($val);
  }

  @mixin background-radiad-gradient($val) {
    background-image: -webkit-radial-gradient($val);
    background-image: -moz-radial-gradient($val);
    background-image: -o-radial-gradient($val);
    background-image: radial-gradient($val);
  }

  @mixin backface-visibility($val) {
    -webkit-backface-visibility: $val;
    -moz-backface-visibility: $val;
    backface-visibility: $val;
  }

  @mixin filter($val) {
    -webkit-filter: $val;
    filter: $val;
  }

  @mixin box-shadow($val) {
    -webkit-box-shadow: $val;
    -moz-box-shadow: $val;
    box-shadow: $val;
  }

  @mixin transition($val) {
    -webkit-transition: $val;
    -moz-transition: $val;
    -o-transition: $val;
    transition: $val;
  }

  @mixin transition-delay($val) {
    -webkit-transition-delay: $val;
    -moz-transition-delay: $val;
    -o-transition-delay: $val;
    transition-delay: $val;
  }

  @mixin transition-property($val){
    -webkit-transition-property: $val;
    -moz-transition-property: $val;
    -o-transition-property: $val;
    transition-property: $val;
  }

  // Text
  // --------------------------
  @mixin font-smoothing() {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  @mixin line-clamp($val) {
    display: -webkit-box;
    -webkit-line-clamp: $val;
    -webkit-box-orient: vertical;
    overflow: hidden;
    @include transform(translate3d(0, 0, 0));
  }

  @mixin wysiwyg(){
    font-size: 1.063rem;
    font-weight: 300;
    line-height: 1.706;
    letter-spacing: 0.12px;

    h1{
      font-size: 1.875rem;
      font-weight: 700;
      line-height: 1.333;
      letter-spacing: 0.12px;
    }

    h2{
      font-size: 1.75rem;
      font-weight: 600;
      line-height: 1.25;
      letter-spacing: 0.12px;
    }

    h3{
      font-size: 1.438rem;
      font-weight: 600;
      line-height: 1.25;
      letter-spacing: 0.12px;
    }

    h4{
      font-size: 1.25rem;
      font-weight: 300;
      line-height: 1.4;
      letter-spacing: 0.12px;
    }

    h5{
      font-size: 1.125rem;
      font-weight: 300;
      line-height: 1.444;
      letter-spacing: 0.12px;
    }

    h6{
      font-size: 1.063rem;
      font-weight: 600;
      line-height: 1.235;
      letter-spacing: 0.12px;
    }

    p{
      font-size: 1.063rem;
      font-weight: 300;
      line-height: 1.706;
      letter-spacing: normal;
    }

    @media (min-width: 62em){

      h1{
        font-size: 3.75rem;
        line-height: 1.217;
      }

      h2{
        font-size: 2.188rem;
        line-height: 1.143;
      }

      h3{
        font-size: 1.75rem;
      }

      h4{
        font-size: 1.563rem;
        line-height: 1.32;
      }

      h5{
        font-size: 1.25rem;
        line-height: 1.65;
      }
    }
  }

