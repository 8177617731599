@import '../../styles/_inocrowd-variables.scss';

.under-maintenance-wrapper {
  padding-top: 50px;
  text-align: center;
  padding-bottom: 100px;

  h1 {
    color: $color-text-grayed;
    font-size: 1.75rem;
    font-weight: 400;
    padding-top: 70px;
    padding-left: 58px;
  }

  img {
    width: 100%;
    height: auto;
  }

  div.center {
    padding: 30px 15px;
    text-align: center;

    span {
      color: $color-text-grayed;
      font-size: 1.75rem;
      font-weight: 400;
    }
  }
}
